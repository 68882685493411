import {
  Avatar,
  Button,
  Flex,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  Select,
  Badge
} from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import React, { useMemo,useState,useEffect } from "react";
import { MdChevronRight, MdChevronLeft,MdDelete,MdEdit,MdBlock } from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { ASSETS_URL } from "constants/apiConstants";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "constants/apiConstants";

function SearchTable2(props) {
  const { columnsData, tableData } = props;
  const cancelRef = React.useRef()
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const toast = useToast()
  const [confirmBoxTitle, setConfirmBoxTitle] = useState();
  const [confirmBoxMessage, setConfirmBoxMessage] = useState();
  const [isOpen, setIsOpen] = React.useState()
  const [agentId, setAgentId] = React.useState()
  const [eventType, setEventType] = useState();

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    state,
    setPageSize
  } = tableInstance;

  const createPages = (count) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const openDeleteAgentConfirmBox = (id,type) => {
    setConfirmBoxTitle("Delete Agent");
    setConfirmBoxMessage("Are you sure you want your delete this listing? This listing will be deleted permanently. This action can’t be undone.");
    setIsOpen(true);
    setAgentId(id);
    setEventType(type);
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const eventHandle = () => {
    setIsOpen(false);
    const payload = {
        'id':agentId
    };
    if(eventType === 1){
      axios.post(API_BASE_URL+'block/agent',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
        window.location.reload();
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }else if(eventType === 2){
      axios.post(API_BASE_URL+'delete/agent',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
        window.location.reload();
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }
  }

  const openBlockAgentConfirmBox = (id,type,status) => {
    if(status === 1){
      setConfirmBoxTitle("Block Agent");
      setConfirmBoxMessage("are you sure want to block this agent?");
    }else{
      setConfirmBoxTitle("Unblock Agent");
      setConfirmBoxMessage("are you sure want to unblock this agent?");
    }
    setIsOpen(true);
    setAgentId(id);
    setEventType(type);
  }

  const { pageIndex, pageSize } = state;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  useEffect(() => {
    setPageSize(20);
  },[]);
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {confirmBoxTitle}
            </AlertDialogHeader>

            <AlertDialogBody>
              {confirmBoxMessage}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={eventHandle} ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <div className="search-box">
          <Select
            w={{ lg: "70px" }}
            fontSize='sm'
            variant='main'
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}>
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            <option value='40'>40</option>
            <option value='50'>50</option>
          </Select>
          <SearchBar
            onChange={(e) => setGlobalFilter(e.target.value)}
            h='44px'
            w={{ lg: "390px" }}
            borderRadius='16px'
            className="search-box"
          />
        </div>
        <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                  if(cell.column.Header === "ACTIONS") {
                    data = (
                      <Flex cursor='pointer' mx="auto">
                        <Link to={`/admin/agents/edit-agent/${cell.value}`}>
                          <Icon color='secondaryGray.500' as={MdEdit} w='20px' h='20px' />
                        </Link>
                        <Link onClick={e => openBlockAgentConfirmBox(cell.value,1,cell.row.values.status)}>
                          <Icon color='secondaryGray.500' as={MdBlock} w='20px' h='20px'/>
                        </Link>
                        <Link onClick={e => openDeleteAgentConfirmBox(cell.value,2)}>
                            <Icon color='secondaryGray.500' as={MdDelete} w='20px' h='20px' />
                        </Link>
                      </Flex>
                    );
                  }else if (cell.column.Header === "ID") {
                    data = (
                      <Text color={textColor} fontSize='md' fontWeight='500'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "Profile Image") {
                    data = (
                      <Flex align='center'>
                        <Avatar
                          src={ASSETS_URL+"agent/profile/"+cell.value} 
                          h='60px'
                          w='60px'
                          me='10px'
                        />
                      </Flex>
                    );
                  } else if (cell.column.Header === "NAME") {
                    data = (
                      <Flex align='center'>
                        <Text
                          color={textColor}
                          fontSize='md'
                          fontWeight='500'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "USER NAME") {
                      data = (
                        <Flex align='center'>
                          <Text
                            color={textColor}
                            fontSize='md'
                            fontWeight='500'>
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    }else if (cell.column.Header === "Phone Number") {
                      data = (
                        <Flex align='center'>
                          <Text
                            color={textColor}
                            fontSize='md'
                            fontWeight='500'>
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "EMAIL") {
                      data = (
                        <Text color={textColor} fontSize='md' fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Account Type") {
                      data = (
                        <Text color={textColor} fontSize='md' fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Device Type") {
                      if(cell.value === 1){
                        var deviceType = 'Apple';
                      }else if(cell.value === 2){
                        var deviceType = 'Android';
                      }
                      data = (
                        <Text color={textColor} fontSize='md' fontWeight='500'>
                          {deviceType}
                        </Text>
                      );
                    } else if (cell.column.Header === "Status") {
                      if(cell.value === 1){
                        var statusValue = <Badge colorScheme='green'>Active</Badge>;
                      }else if(cell.value === 0){
                        var statusValue = <Badge colorScheme='red'>In Active</Badge>;
                      }

                      data = (
                        <Text color={textColor} fontSize='md' fontWeight='500'>
                          {statusValue}
                        </Text>
                      );
                    } else if (cell.column.Header === "Created At") {
                      data = (
                        <Text color={textColor} fontSize='md' fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor={borderColor}>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex
          direction={{ sm: "column", md: "row" }}
          justify='space-between'
          align='center'
          w='100%'
          px={{ md: "22px" }}>
          <Text
            fontSize='sm'
            color='gray.500'
            fontWeight='normal'
            mb={{ sm: "24px", md: "0px" }}>
            Showing {pageSize * pageIndex + 1} to{" "}
            {pageSize * (pageIndex + 1) <= tableData.length
              ? pageSize * (pageIndex + 1)
              : tableData.length}{" "}
            of {tableData.length} entries
          </Text>
          <Stack direction='row' alignSelf='flex-end' spacing='4px' ms='auto'>
            <Button
              variant='no-effects'
              onClick={() => previousPage()}
              transition='all .5s ease'
              w='40px'
              h='40px'
              borderRadius='50%'
              bg='transparent'
              border='1px solid'
              borderColor={useColorModeValue("gray.200", "white")}
              display={
                pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"
              }
              _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
              }}>
              <Icon as={MdChevronLeft} w='16px' h='16px' color={textColor} />
            </Button>
            {pageSize === 5 ? (
              <NumberInput
                max={pageCount - 1}
                min={1}
                w='75px'
                mx='6px'
                defaultValue='1'
                onChange={(e) => gotoPage(e)}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper onClick={() => nextPage()} />
                  <NumberDecrementStepper onClick={() => previousPage()} />
                </NumberInputStepper>
              </NumberInput>
            ) : (
              createPages(pageCount).map((pageNumber, index) => {
                return (
                  <Button
                    variant='no-effects'
                    transition='all .5s ease'
                    onClick={() => gotoPage(pageNumber - 1)}
                    w='40px'
                    h='40px'
                    borderRadius='50%'
                    bg={
                      pageNumber === pageIndex + 1 ? brandColor : "transparent"
                    }
                    border={
                      pageNumber === pageIndex + 1
                        ? "none"
                        : "1px solid lightgray"
                    }
                    _hover={
                      pageNumber === pageIndex + 1
                        ? {
                            opacity: "0.7",
                          }
                        : {
                            bg: "whiteAlpha.100",
                          }
                    }
                    key={index}>
                    <Text
                      fontSize='sm'
                      color={pageNumber === pageIndex + 1 ? "#fff" : textColor}>
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })
            )}
            <Button
              variant='no-effects'
              onClick={() => nextPage()}
              transition='all .5s ease'
              w='40px'
              h='40px'
              borderRadius='50%'
              bg='transparent'
              border='1px solid'
              borderColor={useColorModeValue("gray.200", "white")}
              display={pageSize === 5 ? "none" : canNextPage ? "flex" : "none"}
              _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
              }}>
              <Icon as={MdChevronRight} w='16px' h='16px' color={textColor} />
            </Button>
          </Stack>
        </Flex>
      </Flex>
    </>
  );
}

export default SearchTable2;
